export default {
  /* Main page */
  title: { id: 'delete_account_title', defaultMessage: 'Delete your account' },
  subtitle: {
    id: 'delete_account_subtitle',
    defaultMessage: 'There will be no way back',
  },
  description1: {
    id: 'delete_account_description_1',
    defaultMessage:
      'The deletion of your account is a permanent action. Once your account is deleted, you can no longer access it and it cannot be restored. Upon deletion of your account:',
  },
  bulletPoint1: {
    id: 'delete_account_bullet_point_1',
    defaultMessage:
      'you will no longer have access to the information and data stored in your account (including history of your after-sales messages, your alerts, the history and details of your past orders and trade-in),',
  },
  bulletPoint2: {
    id: 'delete_account_bullet_point_2',
    defaultMessage: 'you will stop receiving follow-up communications,',
  },
  bulletPoint3: {
    id: 'delete_account_bullet_point_3',
    defaultMessage:
      'you will have to create a new account if you want to place a new order or use the trade-in services on Back Market. ',
  },
  description2: {
    id: 'delete_account_description_2',
    defaultMessage:
      'If you click on “Delete my account”, you will be automatically logged out of your account.',
  },
  confirmationCheckbox: {
    id: 'delete_account_confirmation_checkbox',
    defaultMessage: 'Yes, I want to permanently delete my Back Market account.',
  },
  button: {
    id: 'delete_account_delete_account_button',
    defaultMessage: 'Delete your account',
  },
  legalFooter: {
    id: 'delete_account_legal_footer',
    defaultMessage:
      'Please be advised that some data may be retained for a certain period if purchase or trade-in has occurred. For more details about our data privacy practices, we encourage you to visit our Platform and review our updated {link, html}.',
  },
  legalFooterLink: {
    id: 'delete_account_legal_footer_link',
    defaultMessage: 'Privacy Policy',
  },
  /* Confirmation modal */
  confirmationModalContent: {
    id: 'delete_account_confirmation_modal_content',
    defaultMessage:
      'Last call: are you 100% sure you want to delete your account forever?',
  },
  confirmationModalCancelButton: {
    id: 'delete_account_confirmation_modal_cancel_button',
    defaultMessage: 'Cancel',
  },
  confirmationModalDeleteButton: {
    id: 'delete_account_confirmation_modal_delete_button',
    defaultMessage: 'Delete',
  },
  /* Confirmation toast */
  confirmationToastContent: {
    id: 'delete_account_confirmation_toast_content',
    defaultMessage: 'Your account has successfully been deleted.',
  },
  /* Rejection modal */
  rejectionModalHeader: {
    id: 'delete_account_rejection_modal_header',
    defaultMessage: 'Contact support',
  },
  rejectionModalContent1: {
    id: 'delete_account_rejection_modal_content_1',
    defaultMessage:
      'There is an issue with the deletion of your account: Your account does not meet the requirements for autonomous deletion (money on your Back Market virtual wallet, ongoing order or trade-in).',
  },
  rejectionModalContent2: {
    id: 'delete_account_rejection_modal_content_2',
    defaultMessage:
      'If you still wish to proceed with the deletion of your account, please send an email with the subject line “Deletion Request” to {email, html} using the email address associated with your Back Market account.',
  },
  rejectionModalButton: {
    id: 'delete_account_rejection_modal_button',
    defaultMessage: 'Close',
  },
  confirmationModalHeader: {
    id: 'delete_account_confirmation_modal_header',
    defaultMessage: 'Last call',
  },
  emailSubject: {
    id: 'delete_account_email_subject',
    defaultMessage: 'Anonymization request',
  },
  /* Warranty warning modal */
  warrantyModalTitle: {
    id: 'delete_account_warranty_confirmation_modal_title',
    defaultMessage: 'Orders under warranty',
  },
  warrantyModalContent1: {
    id: 'delete_account_warranty_confirmation_modal_content_1',
    defaultMessage:
      'Your account is eligible but you still have ongoing warranties. Please make sure to to download your invoices before continue.',
  },
  warrantyModalInvoicesLink: {
    id: 'delete_account_warranty_confirmation_modal_donwload_invoices_link',
    defaultMessage: 'How to download invoices?',
  },
  warrantyModalContent2: {
    id: 'delete_account_warranty_confirmation_modal_content_2',
    defaultMessage: 'Once it’s done, you can delete your account.',
  },
  warrantyModalDeleteButton: {
    id: 'delete_account_warranty_confirmation_modal_delete_button',
    defaultMessage: 'Delete',
  },
  warrantyModalButton2: {
    id: 'delete_account_warranty_confirmation_modal_button_2',
    defaultMessage: 'Go to orders',
  },
}
