<template>
  <!-- Main page -->
  <RevContainer class="my-24 space-x-12">
    <h1 class="punchline mb-24">{{ i18n(translations.title) }}</h1>
    <h2 class="heading-1 mb-20">{{ i18n(translations.subtitle) }}</h2>

    <p>{{ i18n(translations.description1) }}</p>
    <ul class="list-disc pl-20">
      <li>{{ i18n(translations.bulletPoint1) }}</li>
      <li>{{ i18n(translations.bulletPoint2) }}</li>
      <li>{{ i18n(translations.bulletPoint3) }}</li>
    </ul>
    <p>{{ i18n(translations.description2) }}</p>

    <RevCheckbox
      id="delete-account-checkbox"
      v-model="allowDeleteAccount"
      class="mt-20"
    >
      <template #label>{{ i18n(translations.confirmationCheckbox) }}</template>
    </RevCheckbox>

    <RevButton
      class="mb-20 mt-20"
      :disabled="!allowDeleteAccount"
      variant="primaryDestructive"
      @click="openConfirmationModal"
    >
      {{ i18n(translations.button) }}
    </RevButton>
    <p>
      <FormattedMessage :definition="translations.legalFooter">
        <template #link>
          <RevLink
            target="_blank"
            :to="{
              name: CMS.LEGAL_PAGE,
              params: {
                pageName: LEGAL_PAGE_NAME.DATA_PROTECTION,
                locale: currentLocale,
              },
            }"
          >
            {{ i18n(translations.legalFooterLink) }}
          </RevLink>
        </template>
      </FormattedMessage>
    </p>
  </RevContainer>

  <!-- Confirmation modal -->
  <RevDialog
    :close-button-label="i18n(translations.confirmationModalCancelButton)"
    :name="DELETE_ACCOUNT_CONFIRMATION_MODAL"
    :title="i18n(translations.confirmationModalHeader)"
  >
    <template #body>
      {{ i18n(translations.confirmationModalContent) }}
    </template>
    <template #footer="{ close }">
      <RevButton
        class="mb-8"
        full-width="always"
        variant="primary"
        @click="close"
      >
        {{ i18n(translations.confirmationModalCancelButton) }}
      </RevButton>
      <RevButton
        class="mb-8"
        :disabled="isDisabling"
        full-width="always"
        :loading="isDisabling"
        variant="primaryDestructive"
        @click="deleteAccount({ force: false })"
      >
        {{ i18n(translations.confirmationModalDeleteButton) }}
      </RevButton>
    </template>
  </RevDialog>

  <!-- Warranty warning modal -->
  <RevDialog
    :close-button-label="i18n(translations.warrantyModalButton2)"
    :name="WARRANTY_WARNING_MODAL"
    :title="i18n(translations.warrantyModalTitle)"
  >
    <template #body>
      <p>{{ i18n(translations.warrantyModalContent1) }}</p>
      <p class="my-20">
        <RevLink target="_blank" :to="articleUrl">
          {{ i18n(translations.warrantyModalInvoicesLink) }}
        </RevLink>
      </p>
      <p class="body-1-bold">{{ i18n(translations.warrantyModalContent2) }}</p>
    </template>
    <template #footer>
      <RevButton
        class="mb-8"
        :disabled="isDisabling"
        full-width="always"
        :loading="isDisabling"
        variant="primaryDestructive"
        @click="deleteAccount({ force: true })"
      >
        {{ i18n(translations.warrantyModalDeleteButton) }}
      </RevButton>
      <RevButton
        class="mb-8"
        full-width="always"
        :to="{ name: ROUTE_NAMES.DASHBOARD.MY_ORDERS.SELF }"
        variant="primary"
      >
        {{ i18n(translations.warrantyModalButton2) }}
      </RevButton>
    </template>
  </RevDialog>

  <!-- Rejection modal -->
  <RevDialog
    :close-button-label="i18n(translations.rejectionModalButton)"
    :name="DELETE_ACCOUNT_REJECTION_MODAL"
    :title="i18n(translations.rejectionModalHeader)"
  >
    <template #body>
      <p>{{ i18n(translations.rejectionModalContent1) }}</p>
      <br />
      <FormattedMessage :definition="translations.rejectionModalContent2">
        <template #email>
          <RevLink mailTo target="_blank" :to="mailTo">{{ email }}</RevLink>
        </template>
      </FormattedMessage>
    </template>
    <template #footer="{ close }">
      <RevButton
        class="mb-8"
        full-width="always"
        variant="primary"
        @click="close"
      >
        {{ i18n(translations.rejectionModalButton) }}
      </RevButton>
    </template>
  </RevDialog>
</template>

<script setup lang="ts">
import { useZendeskFaqBaseUrl } from '#imports'
import { ref } from 'vue'

import { HttpApiError } from '@backmarket/http-api'
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'
import { useDeleteClientAccount } from '@backmarket/nuxt-module-oauth/useDeleteAccount'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevButton } from '@ds/components/Button'
import { RevCheckbox } from '@ds/components/Checkbox'
import { RevContainer } from '@ds/components/Container'
import { RevDialog } from '@ds/components/Dialog'
import { RevLink } from '@ds/components/Link'
import { openModal } from '@ds/components/ModalBase'

import { CMS } from '~/scopes/cms/routes-names'
import { ROUTE_NAMES } from '~/scopes/dashboard/constants'
import { LEGAL_PAGE_NAME } from '~/scopes/legal/constants'

import {
  DELETE_ACCOUNT_CONFIRMATION_MODAL,
  DELETE_ACCOUNT_REJECTION_MODAL,
  WARRANTY_ERROR,
  WARRANTY_WARNING_MODAL,
} from './DeleteAccount.constants'
import translations from './DeleteAccount.translations'

const i18n = useI18n()
const currentLocale = useI18nLocale()
const allowDeleteAccount = ref(false)
const isDisabling = ref(false)
const email = 'legal+account@backmarket.com'
const emailSubject = i18n(translations.emailSubject)
const mailTo = `mailto:${email}?subject=${emailSubject}`
const tracking = useTracking()
const { openSuccessToast } = useTheToast()
const { deleteClientAccount } = useDeleteClientAccount()
const { zendeskFaqBaseUrl } = useZendeskFaqBaseUrl()
const articleUrl = zendeskFaqBaseUrl.concat('/articles/360013855619')

async function openConfirmationModal() {
  tracking.trackClick({
    page_type: 'anonymization',
    name: 'anonymize_account',
  })
  openModal(DELETE_ACCOUNT_CONFIRMATION_MODAL)
}

interface DeleteAccountParams {
  force: boolean
}

async function deleteAccount({ force = false }: DeleteAccountParams) {
  tracking.trackClick({
    page_type: 'anonymization',
    name: force
      ? 'confirm_anonymize_account_with_warranty'
      : 'confirm_anonymize_account',
  })
  isDisabling.value = true
  try {
    await deleteClientAccount(force)
    openSuccessToast({
      content: i18n(translations.confirmationToastContent),
    })
    tracking.trackToastView({
      name: force
        ? 'anonymization_performed_with_warranty'
        : 'anonymization_performed',
    })
  } catch (error) {
    const { title } = HttpApiError.fromAnyError(error as Error)
    if (title === WARRANTY_ERROR) {
      tracking.trackModal({
        page_type: 'anonymization',
        name: 'anonymization_ongoing_warranty',
        status: 'opened',
      })
      openModal(WARRANTY_WARNING_MODAL)
    } else {
      tracking.trackModal({
        page_type: 'anonymization',
        name: 'anonymization_not_elligible',
        status: 'opened',
      })
      openModal(DELETE_ACCOUNT_REJECTION_MODAL)
    }
  } finally {
    isDisabling.value = false
  }
}
</script>
